import React from "react";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { WHATSAPP_CONTACT_URL } from "../../constants";
import { CallIcon, EmailIcon, WhatsAppIconFilled } from "../../../assets/svg";
import { capitalizeWord } from "../../utils";
import IconButton from "../IconButton/IconButton";

function ContactCardButton({
  type,
  visible,
  removeNote,
  handleRemove,
  okText,
  id,
  itemKey,
  title,
  data,
}) {
  return (
    <Modal
      visible={visible}
      onCancel={removeNote}
      onOk={() => handleRemove(id, itemKey)}
      centered
      closable={false}
      okText={okText}
      cancelText="Close"
      title={title}
      footer={[
        <Button key="back" type="text" onClick={removeNote}>
          Close
        </Button>,
      ]}
      className="contact-card-button-modal"
    >
      {data?.map((actionType, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="modal-body" key={i}>
            <div className="modal-body-number-box">
              <div>{capitalizeWord(actionType?.type)}</div>
              <div className="modal-body-number-box-number">
                {actionType?.value}
              </div>
            </div>
            <div className="modal-body-icon">
              {type === "CALL" && (
                <IconButton
                  type="text"
                  icon={<CallIcon />}
                  handleClick={() => {
                    window.location = `tel:${actionType?.value}`;
                  }}
                />
              )}
              {type === "EMAIL" && (
                <IconButton
                  type="text"
                  icon={<EmailIcon />}
                  handleClick={() => {
                    window.location = `mailto:${actionType?.value}`;
                  }}
                />
              )}
              {type === "WHATSAPP" && (
                <IconButton
                  type="text"
                  icon={<WhatsAppIconFilled />}
                  handleClick={() => {
                    const phoneNumber = actionType?.value.replace(/\D/g, ""); // Remove non-numeric characters
                    const url = `${WHATSAPP_CONTACT_URL}${phoneNumber}`;
                    window.open(url, "_blank"); // Open the link in a new tab
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </Modal>
  );
}

export default ContactCardButton;
